import formatValue from '../../../app/product/features/goals/utils/formatValue'

export const getFieldIds = fields => {
  const fieldIds = {}
  for (const field of fields) {
    if (field.name && field.fieldId) {
      fieldIds[field.name] = field.fieldId
    }
  }
  return fieldIds
}

export const formatName = name => {
  const trimmed = name.trim()
  return trimmed.charAt(0).toUpperCase() + trimmed.slice(1).toLowerCase()
}

export const createCompanyName = (firstName, lastName) => {
  const first = formatName(firstName)
  const last = formatName(lastName)
  if (last[last.length - 1] === 's' || last[last.length - 1] === 'S') {
    return `${first} ${last}' Company`
  }
  return `${first} ${last}'s Company`
}

export const prepopulateFields = (fetchedForm, currentForm) => {
  const fieldValues = fetchedForm.formSections
    .map(section => section.formFields.map(field => ({ [field.name]: field.value })))
    .flat()
    .reduce((acc, x) => {
      for (const key in x) acc[key] = x[key]
      return acc
    }, {})
  const fieldNames = Object.keys(fieldValues)
  const fields = currentForm.map(field => {
    if (fieldNames.includes(field.name)) return { ...field, value: formatValue(fieldValues[field.name], field.type, field.options) }
    else return field
  })
  if (fieldValues?.notYetTrading || fieldValues?.tradingAs === 'Not yet incorporated') {
    return fields.filter(field => field.name !== 'turnoverLastYear')
  }
  return fields
}

export const handleTurnoverLastYearDependencies = (turnoverLastYearField, shouldAddDependencies) => {
  if (shouldAddDependencies) {
    turnoverLastYearField.dependencies = [
      {
        value: 'Private limited company (LTD)',
        field: 'tradingAs'
      },
      {
        value: 'Limited liability partnership (LLP)',
        field: 'tradingAs'
      },
      {
        value: 'Partnership',
        field: 'tradingAs'
      },
      {
        value: 'Public limited company (PLC)',
        field: 'tradingAs'
      },
      {
        value: 'Sole trader',
        field: 'tradingAs'
      },
      {
        value: 'Sole Proprietorship',
        field: 'tradingAs'
      },
      {
        value: 'Limited Liability Company (LLC)',
        field: 'tradingAs'
      },
      {
        value: 'Corporation',
        field: 'tradingAs'
      }
    ]
    turnoverLastYearField.dependencyRule = 'or'
  } else {
    turnoverLastYearField.dependencies = null
    turnoverLastYearField.dependencyRule = null
  }
}
