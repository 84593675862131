import { swoopPartnerTypeCookieName } from '@/utils/constants'
import { createCompanyName, formatName, getFieldIds } from './helpers'
import { onlyNumbers } from '@/utils/currency'
import { getCookie, getSubdomain } from '@/utils/utils'

export const formatDynamicFormPayload = (fields, fieldIds, sectionId, isComplete) => {
  const fieldValues = Object.keys(fields).map(key => ({
    fieldId: fieldIds[key],
    value: fields[key],
    name: key
  }))
  return {
    sections: [
      {
        id: sectionId,
        isCompleted: isComplete,
        fieldValues: fieldValues
      }
    ],
    isFormCompleted: isComplete
  }
}

export const formatEnteredCompanyData = (values, formId = undefined) => {
  return {
    sourceType: 'Entered',
    isCantFindCompany: true,
    name: values.companyNameNotDecided ? createCompanyName(values.firstName, values.lastName) : values.companyName,
    country: values.country,
    ...(values.region && { region: values.region }),
    ...(values.county && { county: values.county }),
    ...(values.district && { district: values.district }),
    ...(formId && { formId })
  }
}

export const formatEditFormPayload = (values, formFields, sectionId, currentForm) => {
  return {
    dynamic: {
      fieldIds: getFieldIds(formFields),
      sectionId: sectionId,
      formId: currentForm.formId,
      fields: {
        turnoverLastYear: onlyNumbers(values.turnoverLastYear),
        whenFundingNeeded: values.whenFundingNeededHaventDecided ? '3' : values.whenFundingNeeded,
        whenFundingNeededHaventDecided: values.whenFundingNeededHaventDecided,
        fundingAmount: onlyNumbers(values.fundingAmount),
        sectors: values.sectors
      }
    },
    fundingReason: values.fundingReason,
    productType: currentForm.type,
    skipDetailsPage: currentForm.skipDetailsPage
  }
}

export const formatRegistrationPayload = (values, formFields, enteredCompanyDetails, findCompanyData, sectionId, tenant, fromInvite, inviteSource, currentForm = undefined) => {
  return {
    registrationData: {
      company: {
        ...(enteredCompanyDetails ? { ...enteredCompanyDetails } : {
          ...findCompanyData,
          ...(currentForm && { formId: currentForm.formId }),
          name: findCompanyData.companyName
        })
      },
      originType: getCookie(swoopPartnerTypeCookieName) || null,
      origin: window.WHITE_LABEL_STYLES?.subdomain !== 'swoop' ? getSubdomain() : getCookie('utm_source') || 'organic',
      isWhitelabel: window.WHITE_LABEL_STYLES?.subdomain !== 'swoop',
      contactNumber: values.contactNumber,
      firstName: formatName(values.firstName),
      lastName: formatName(values.lastName),
      email: values.email.trim(),
      ...(values.password && { password: values.password }),
      type: 'user',
      isEmailMarketing: values.isEmailMarketing || false,
      fromInvite,
      inviteSource,
      tenant
    },
    dynamic: {
      sectionId,
      fieldIds: getFieldIds(formFields),
      ...(currentForm && { formId: currentForm.formId }),
      fields: {
        ...(values.tradingStartDate && { tradingStartDate: values.tradingStartDate }),
        ...(values.turnoverLastYear && { turnoverLastYear: onlyNumbers(values.turnoverLastYear) }),
        ...(values.notYetTrading && { notYetTrading: values.notYetTrading }),
        ...(values.tradingAs && { tradingAs: values.tradingAs }),
        ...(values.whenFundingNeeded && { whenFundingNeeded: values.whenFundingNeededHaventDecided ? '3' : values.whenFundingNeeded }),
        ...(values.whenFundingNeededHaventDecided && { whenFundingNeededHaventDecided: values.whenFundingNeededHaventDecided }),
        ...(values.fundingAmount && { fundingAmount: onlyNumbers(values.fundingAmount) }),
        ...(values.sectors && { sectors: values.sectors })
      }
    },
    ...(values.fundingReason && { fundingReason: values.fundingReason }),
    ...(currentForm && {
      productType: currentForm.type,
      skipDetailsPage: currentForm.skipDetailsPage
    })
  }
}
